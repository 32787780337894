import React, { memo, MutableRefObject, useEffect } from 'react';

import { Cell, CellText, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { GeoObjectsContext, YMaps } from 'Modules/Maps/maps.types';
import translation from 'src/components/translation';

import TextArea from 'src/components/AddressSuggest/AddressDescription';
import Metros from 'src/components/AddressSuggest/Metros';
import { AddressState, Metro, MetroStation } from 'src/components/AddressSuggest/types';

const TrlKeys = {
    editAddress: 'employer.address.edit.address',
    editDescription: 'employer.address.edit.description',
    editPlaceholder: 'employer.address.edit.description.placeholder',
    buttonAdd: 'employer.address.edit.button.add',
    buttonReset: 'employer.address.edit.reset',
    buttonEdit: 'employer.address.edit.button.edit',
};

interface AddressSelectedFromSearchProps {
    addressState: AddressState;
    fullAddress: string;
    description: string;
    updateMap: (geoObjects: GeoObjectsContext, draggable: boolean) => void;
    currentSelectedMetro: MetroStation[];
    currentAddressId?: number;
    coordinates: number[];
    city: string;
    ymapsInstance: MutableRefObject<YMaps | null>;
    currentGeoObjectsContextRef: MutableRefObject<GeoObjectsContext | undefined>;
    getMapInstance: () => void;
    setSelectedMetros: (metros: Metro[]) => void;
    setDescription: (description: string) => void;
}

const AddressSelectedFromSearch: TranslatedComponent<AddressSelectedFromSearchProps> = ({
    trls,
    addressState,
    description,
    fullAddress,
    currentSelectedMetro,
    currentAddressId,
    city,
    coordinates,
    getMapInstance,
    updateMap,
    ymapsInstance,
    currentGeoObjectsContextRef,
    setSelectedMetros,
    setDescription,
}) => {
    useEffect(() => {
        getMapInstance();
    }, [getMapInstance, currentAddressId]);

    if (addressState !== AddressState.Address) {
        return null;
    }

    return (
        <div>
            <Cell>
                <CellText type="title">{trls[TrlKeys.editAddress]}</CellText>
                <CellText type="subtitle">{fullAddress}</CellText>
            </Cell>
            <Metros
                selectedMetro={currentSelectedMetro}
                coordinates={coordinates}
                city={city}
                onMapUpdate={(coordinates) => {
                    if (ymapsInstance.current) {
                        const collection = new ymapsInstance.current.GeoObjectCollection();
                        coordinates.forEach(({ lat, lng }) => {
                            if (ymapsInstance.current) {
                                const placemark = new ymapsInstance.current.Placemark(
                                    [lat, lng],
                                    {},
                                    {
                                        visible: false,
                                    }
                                );
                                collection.add(placemark);
                            }
                        });
                        collection.add(currentGeoObjectsContextRef.current);
                        updateMap(collection, true);
                    }
                }}
                onMetroSelect={setSelectedMetros}
            />
            <VSpacing default={24} />
            <TextArea
                data-qa="address-edit-description"
                placeholder={trls[TrlKeys.editPlaceholder]}
                value={description}
                onChange={setDescription}
            />
        </div>
    );
};

export default memo(translation(AddressSelectedFromSearch));
