import { forwardRef, cloneElement, useCallback } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const DragElement = ({ children, onDragStart, index, active, hasDraggedElements }, ref) => {
    const onDragStartWrapper = useCallback(
        (event) => {
            onDragStart(event, index);
        },
        [index, onDragStart]
    );

    const getMovedElementProps = useCallback(() => {
        return {
            onMouseDown: onDragStartWrapper,
            onTouchStart: onDragStartWrapper,
        };
    }, [onDragStartWrapper]);

    return (
        <div ref={ref} className={classnames({ 'constructor-drag-element-active': active })}>
            {cloneElement(children, { getMovedElementProps, dragged: active, hasDraggedElements })}
        </div>
    );
};

DragElement.propTypes = {
    children: PropTypes.node.isRequired,
    active: PropTypes.bool,
    index: PropTypes.number.isRequired,
    onDragStart: PropTypes.func.isRequired,
    hasDraggedElements: PropTypes.bool,
};

export default forwardRef(DragElement);
