import { useCallback, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Input } from '@hh.ru/magritte-ui';
import { MagnifierOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import {
    IconDynamic,
    IconLink,
    IconColor,
    CrossScaleSmallEnclosedFalse,
    LupaScaleSmallKindDefaultAppearanceOutlined,
} from 'bloko/blocks/icon';
import InputText from 'bloko/blocks/inputText';
import Suggest, { SuggestLayer } from 'bloko/blocks/suggest';
import createRemoteDataProvider from 'bloko/blocks/suggest/createRemoteDataProvider';
import createStaticDataProvider from 'bloko/blocks/suggest/createStaticDataProvider';

import { prepareAddresses, EMPTY_ITEM } from 'Modules/EmployerAddresses/helpers';
import translation from 'src/components/translation';

import AddressItem from 'src/components/AddressSuggest/AddressItem';

const WILDCARD = '%QUERY%';

const TrlKeys = {
    metroStation: 'metrostation',
};

const AddressBlokoSuggest = ({
    addresses,
    remoteSearch,
    address,
    inputValue,
    trls,
    onSelect,
    selectOnBlur,
    searchOnFocus,
    placeholder,
    invalid,
    setInvalid = () => {},
    inputProps,
    limit,
    layer,
    redesigned,
    addressIdNotToShow,
    onClickAnalytics = () => {},
    employerId = '',
}) => {
    const inputRef = useRef(null);
    const inputWrapperRef = useRef(null);
    const handleInputFocus = () => setInvalid(false);
    const handleInputButtonIconClick = useCallback(() => {
        if (address !== EMPTY_ITEM) {
            onSelect(EMPTY_ITEM, true);
        }
        setTimeout(() => inputRef.current.focus(), 0);
    }, [address, onSelect]);

    const dataProvider = useMemo(() => {
        if (remoteSearch) {
            const searchUrl = `/employer/addresses/search?limit=${limit}&searchText=${WILDCARD}&employerId=${employerId}`;
            return (query) => {
                const dataProvider = createRemoteDataProvider(searchUrl, WILDCARD);
                return dataProvider(query).then(({ addresses }) => ({
                    items: prepareAddresses(addresses, trls[TrlKeys.metroStation], addressIdNotToShow),
                }));
            };
        }
        return createStaticDataProvider(prepareAddresses(addresses, trls[TrlKeys.metroStation], addressIdNotToShow));
    }, [addressIdNotToShow, addresses, employerId, limit, remoteSearch, trls]);

    const inputEnabled = !inputProps.disabled;

    return (
        <Suggest
            dataProvider={dataProvider}
            limit={limit}
            // eslint-disable-next-line local-rules/bloko-use-selectable-attributes
            layer={layer}
            suggestStartInputLength={0}
            itemContent={AddressItem}
            selectOnBlur={selectOnBlur}
            searchOnFocus={searchOnFocus}
            positionElementRef={redesigned ? inputWrapperRef : undefined}
            onChange={(item) => {
                item && onSelect(item);
            }}
            value={{ ...address, text: inputValue }}
        >
            {redesigned ? (
                <Input
                    wrapperRef={inputWrapperRef}
                    onClick={onClickAnalytics}
                    placeholder={placeholder}
                    ref={inputRef}
                    invalid={invalid}
                    onFocus={handleInputFocus}
                    buttonIcon={inputEnabled ? MagnifierOutlinedSize24 : undefined}
                    onButtonClick={handleInputButtonIconClick}
                    data-qa="address-suggest-input"
                    clearable
                    {...inputProps}
                    onChange={(value) => inputProps.onChange(value, { element: inputRef.current })}
                />
            ) : (
                <InputText
                    onClick={onClickAnalytics}
                    placeholder={placeholder}
                    ref={inputRef}
                    invalid={invalid}
                    onFocus={handleInputFocus}
                    icon={
                        inputEnabled && (
                            <IconDynamic>
                                <IconLink onClick={handleInputButtonIconClick}>
                                    {address !== EMPTY_ITEM ? (
                                        <CrossScaleSmallEnclosedFalse
                                            initial={IconColor.Gray50}
                                            highlighted={IconColor.Gray60}
                                        />
                                    ) : (
                                        <LupaScaleSmallKindDefaultAppearanceOutlined
                                            initial={IconColor.Gray50}
                                            highlighted={IconColor.Gray60}
                                        />
                                    )}
                                </IconLink>
                            </IconDynamic>
                        )
                    }
                    data-qa="address-suggest-input"
                    {...inputProps}
                    autoComplete="off"
                />
            )}
        </Suggest>
    );
};

AddressBlokoSuggest.propTypes = {
    /** Адреса работодателя, как они лежат в стейте */
    addresses: PropTypes.arrayOf(PropTypes.object),
    /** При установке в true, addresses будет игнорироваться, и саджест будет работать с удаленным поиском адресов */
    remoteSearch: PropTypes.bool,
    /** Выбранный адрес */
    address: PropTypes.object,
    /** Значение которое должно быть в input-e если элемент используется как контролируемый */
    inputValue: PropTypes.string,
    /** переводы */
    trls: PropTypes.object,
    /** Колбэк вызывающийся при выборе элемента из саджеста, принимает выбранный адрес */
    onSelect: PropTypes.func,
    /** Флаг для включения отображения в новом дизайне magritte-ui */
    redesigned: PropTypes.bool,
    /** bloko/suggest -> selectOnBlur */
    selectOnBlur: PropTypes.bool,
    /** bloko/suggest -> searchOnFocus */
    searchOnFocus: PropTypes.bool,
    /** placeholder input-а */
    placeholder: PropTypes.string,
    /** Invalid свойство для input-a */
    invalid: PropTypes.bool,
    /** Колбэк для установки invalid свойства input-а */
    setInvalid: PropTypes.func,
    /** Другие параметры, которые будут переданы в input */
    inputProps: PropTypes.object,
    /** Максимальное количество отображаемых в дропдауне результатов */
    limit: PropTypes.number,
    /** Аналитика клика по инпуту */
    onClickAnalytics: PropTypes.func,
    /** Положение списка по оси Z (устанавливает z-index) */
    layer: PropTypes.oneOf(Object.values(SuggestLayer)),
    /** id адреса, который не будет отображаться в дропдауне */
    addressIdNotToShow: PropTypes.number,
    /** employerId, который нужен чтобы получить список адресов под бэкофисом */
    employerId: PropTypes.string,
};

export default translation(AddressBlokoSuggest);
