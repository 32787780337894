import { Fragment, FC } from 'react';

import Text, { TextSize } from 'bloko/blocks/text';

import styles from 'src/components/AddressSuggest/address-item.less';

interface AddressItemProps {
    id?: string | number;
    displayName?: string;
    description?: string;
    metroStations?: {
        name: string;
        line: { color: string };
    }[];
}

const AddressItem: FC<AddressItemProps> = ({ id, displayName, metroStations, description }) => {
    return (
        <div data-qa={`address-item ${id || ''}`}>
            {displayName}
            {metroStations && (
                <div>
                    {metroStations.map((station) => (
                        <Fragment key={`${station.name}_${station.line.color}`}>
                            <span className={styles.metroStation}>
                                <span className="metro-point" style={{ color: station.line.color }} />
                                <span data-qa="address-item-metro">{station.name}</span>
                            </span>{' '}
                        </Fragment>
                    ))}
                </div>
            )}
            {description && (
                <Text size={TextSize.Small}>
                    <div className="address-select-description">{description}</div>
                </Text>
            )}
        </div>
    );
};

export default AddressItem;
