import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ButtonKind } from 'bloko/blocks/button';
import Gap from 'bloko/blocks/gap';

import FileLoadButton from 'src/components/EmployerConstructor/widgets/components/FileLoadButton';

const LoadArea = ({ hintText, className, buttonText, isLoading, disabled, inputProps }) => (
    <div className={classNames('widget-edit-description', className)}>
        <div className="widget-edit-description__text">
            {hintText}
            <Gap top>
                <FileLoadButton
                    kind={ButtonKind.Success}
                    isLoading={isLoading}
                    disabled={disabled || isLoading}
                    inputProps={inputProps}
                >
                    {buttonText}
                </FileLoadButton>
            </Gap>
        </div>
        <div className="widget-edit-description__icon widget-edit-description__icon_image" />
    </div>
);

LoadArea.propTypes = {
    className: PropTypes.string,
    hintText: PropTypes.node,
    buttonText: PropTypes.node,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    inputProps: PropTypes.object,
};

export default LoadArea;
