import { Snackbar } from '@hh.ru/magritte-ui';
import { MinusCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import { AddressDuplicateType } from 'src/components/AddressSuggest/types';

export const ALREADY_EXIST = 'address.alreadyExists';
export const METRO_TOO_MANY = 'address.metroTooMany';

const TrlKeys = {
    useExisting: 'employer.address.use.existing',
    showExisting: 'employer.address.show.existing',
    addressAlreadyExist: 'employer.address.edit.address.alreadyExists',
    metroTooMany: 'employer.address.edit.address.metroTooMany',
};

interface AddressSavingErrorProps {
    duplicate: AddressDuplicateType;
    savedError?: string;
    setSavedError: (error: string) => void;
    onClick?: () => void;
}

const AddressSavingError: TranslatedComponent<AddressSavingErrorProps> = ({
    trls,
    duplicate,
    savedError,
    setSavedError,
    onClick,
}) => {
    const addressSavingError = trls[savedError === ALREADY_EXIST ? TrlKeys.addressAlreadyExist : TrlKeys.metroTooMany];
    const addressAlreadyExistError = trls[duplicate === 'show' ? TrlKeys.showExisting : TrlKeys.useExisting];

    if (!savedError) {
        return null;
    }

    return (
        <Snackbar
            showClose
            autohideTime={3000}
            data-qa="add-address-modal-validation-error"
            addon={<MinusCircleFilledSize24 initial="negative" />}
            onClose={() => setSavedError('')}
            {...(savedError === ALREADY_EXIST
                ? { actionLabel: addressAlreadyExistError, onAction: onClick }
                : undefined)}
        >
            {addressSavingError}
        </Snackbar>
    );
};

export default translation(AddressSavingError);
