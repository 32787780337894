import { YMaps, YMapsInstance } from 'Modules/Maps/maps.types';
import { Coordinate, Metro, MetroStation } from 'src/components/AddressSuggest/types';

export const prepareCoordinates = ({ lat = 0, lng = 0 }: Partial<Coordinate>): Coordinate => ({ lat, lng });

export const prepareMetroItem = ({ id, name, line, lat, lng, ...other }: MetroStation): Metro => ({
    id,
    cityId: other['@cityId'],
    text: name,
    name,
    line,
    lat,
    lng,
});

const prepareAddressParams = (
    coordinates: number[],
    zoom: number
): {
    mapData: {
        points: {
            center: {
                lat: number;
                lng: number;
                zoom: number;
            };
        };
    };
} => {
    const [lat, lng] = coordinates;
    return {
        mapData: {
            points: {
                center: { lat, lng, zoom },
            },
        },
    };
};

export const createYandexMaps = async (
    element: HTMLElement,
    center: number[],
    zoom: number
): Promise<{ ymaps: YMaps | null; mapInstance: YMapsInstance | null }> => {
    let ymaps = null;
    let mapInstance = null;
    if (!process.env.LUX_SERVER) {
        try {
            const showMapModule = await import(/* webpackMode: "eager" */ 'Modules/Maps/showMap');
            const result = await showMapModule.default(element, {
                address: prepareAddressParams(center, zoom),
            });
            let boundsResult;
            try {
                boundsResult = await result.ymaps.geolocation.get({ provider: 'yandex' });
            } catch (e) {
                console.error('Failed to install bounds', e);
            }
            const bounds = boundsResult?.geoObjects.get(0).properties.get('boundedBy');

            if (bounds) {
                result.mapInstance.setBounds(bounds);
            }

            ymaps = result.ymaps;
            mapInstance = result.mapInstance;
        } catch (error) {
            console.error('Yandex Map Instance Creating Error: ', error);
        }
    }
    return { ymaps, mapInstance };
};
/**
 * Обрезает текст до заданной длины и добавляет многоточие, если текст превышает эту длину.
 *
 * @param {string | undefined} text - Текст, который необходимо обрезать.
 * @param {number} length - Максимальная длина текста.
 * @param {boolean} isMobile - Флаг, определяющий, следует ли обрезать текст, даже если он короче заданной длины.
 * @param {string} ellipsis - Многоточие, которое нужно добавить, если текст превышает заданную длину.
 * @return {string} Вырезанный текст с многоточием, если необходимо.
 */
export const cutMetro = (text: string | undefined, length: number, isMobile: boolean, ellipsis = '...'): string => {
    if (!text) {
        return '';
    } else if (text.length <= length || isMobile) {
        return text;
    }

    return `${text.substring(0, length)}${ellipsis}`;
};
