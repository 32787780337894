import { FC } from 'react';

import Text from 'bloko/blocks/text';

import styles from 'src/components/AddressSuggest/add-address-modal.less';

const AddressBalloon: FC = () => {
    return (
        <div className={styles.balloon}>
            <Text strong>$[properties.name]</Text>
        </div>
    );
};

export default AddressBalloon;
