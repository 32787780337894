import { FC, forwardRef, ForwardRefRenderFunction, Ref } from 'react';
import classnames from 'classnames';

import { useBreakpoint } from '@hh.ru/magritte-ui';

import styles from './add-address-modal.less';

interface AddressMapProps {
    fwdRef?: Ref<HTMLDivElement>;
}

const AddressMapRaw: FC<AddressMapProps> = ({ fwdRef }) => {
    const { isMobile } = useBreakpoint();

    return (
        <div
            ref={fwdRef}
            className={classnames(styles.addressModalMapWrapper, {
                [styles.addressModalMapWrapperMobile]: isMobile,
            })}
        />
    );
};

const AddressMap: ForwardRefRenderFunction<HTMLDivElement, AddressMapProps> = (_, ref) => (
    <AddressMapRaw fwdRef={ref} />
);

export default forwardRef(AddressMap);
