import { useMemo } from 'react';
import PropTypes from 'prop-types';

import Button from 'bloko/blocks/button';
import { PlusScaleSmallEnclosedFalse, ArrowsScaleSmallKindDefault, IconColor } from 'bloko/blocks/icon';
import Supports from 'bloko/common/supports';

import DragContainer from 'src/components/EmployerConstructor/drag/Container';
import ImageSlide from 'src/components/EmployerConstructor/widgets/GalleryWidget/components/ImageSlide';
import SidebarItem from 'src/components/EmployerConstructor/widgets/GalleryWidget/components/SidebarItem';
import FileLoadButton from 'src/components/EmployerConstructor/widgets/components/FileLoadButton';

export const getFileFromEvent = (event) => (event.target ? event.target.files[0] : event[0]);

const IS_TOUCH_SUPPORT = typeof window !== 'undefined' && Supports.touch();

const SideBarItemContainer = ({
    editImage,
    uploadImage,
    removePicture,
    src,
    number,
    id,
    getMovedElementProps,
    dragged,
}) => (
    <div {...(!IS_TOUCH_SUPPORT ? getMovedElementProps() : {})}>
        <SidebarItem
            number={number}
            key={id}
            onEdit={() => editImage(id)}
            onUpload={(event) => uploadImage(getFileFromEvent(event), id)}
            onRemove={() => removePicture(id)}
        >
            {IS_TOUCH_SUPPORT && (
                <div className="widget-gallery-item__touch-drag">
                    <Button {...getMovedElementProps()} disabled={dragged} icon={<ArrowsScaleSmallKindDefault />} />
                </div>
            )}
            <ImageSlide src={src} />
        </SidebarItem>
    </div>
);

const SideBarContainer = ({
    canAddPicture,
    editImage,
    pictures,
    uploadImage,
    removePicture,
    setOrder,
    isUploading,
    pictureSettings,
}) => {
    const sortKeys = useMemo(() => pictures.map(({ id }) => id), [pictures]);

    return (
        <div className={'widget-gallery-sidebar'}>
            <div className={'widget-gallery-sidebar__content'}>
                <DragContainer
                    dropZoneClassName="widget-gallery-item-drop-line"
                    dragElementStubClassName="widget-gallery-item_stub"
                    onDrop={setOrder}
                    sortKeys={sortKeys}
                    scrollByContainer
                >
                    {pictures.map(({ src, id }, index) => (
                        <SideBarItemContainer
                            key={id}
                            src={src}
                            id={id}
                            number={index + 1}
                            editImage={editImage}
                            uploadImage={uploadImage}
                            removePicture={removePicture}
                        />
                    ))}
                </DragContainer>
            </div>
            <div className={'widget-gallery-sidebar__bottom-stick'}>
                <FileLoadButton
                    stretched
                    isLoading={isUploading}
                    disabled={isUploading || !canAddPicture}
                    icon={<PlusScaleSmallEnclosedFalse initial={IconColor.Gray50} />}
                    inputProps={{
                        accept: pictureSettings.allowedMimeTypes,
                        onChange: (event) => uploadImage(getFileFromEvent(event)),
                    }}
                />
            </div>
        </div>
    );
};

const pictureSettingsPropTypes = {
    minWidth: PropTypes.number.isRequired,
    minHeight: PropTypes.number.isRequired,
    maxSizeBytes: PropTypes.number.isRequired,
    allowedMimeTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    widthHeightRatio: PropTypes.number.isRequired,
};

SideBarContainer.propTypes = {
    pictures: PropTypes.array.isRequired,
    editImage: PropTypes.func.isRequired,
    uploadImage: PropTypes.func.isRequired,
    removePicture: PropTypes.func.isRequired,
    setOrder: PropTypes.func.isRequired,
    isUploading: PropTypes.bool.isRequired,
    canAddPicture: PropTypes.bool.isRequired,
    pictureSettings: PropTypes.shape(pictureSettingsPropTypes).isRequired,
};

SideBarItemContainer.propTypes = {
    editImage: PropTypes.func.isRequired,
    uploadImage: PropTypes.func.isRequired,
    removePicture: PropTypes.func.isRequired,
    src: PropTypes.string.isRequired,
    number: PropTypes.number,
    id: PropTypes.number,
    getMovedElementProps: PropTypes.func,
    dragged: PropTypes.bool,
};

export default SideBarContainer;
