import PropTypes from 'prop-types';

import SlidesContainer from 'src/components/EmployerConstructor/widgets/GalleryWidget/containers/SlidesContainer';
import useSlides from 'src/components/EmployerConstructor/widgets/GalleryWidget/hooks/useSlides';

const ViewModeContainer = ({ pictures }) => {
    const [slides, activeSlide, setActiveSlide] = useSlides(pictures);

    return (
        <div className="widget-gallery">
            <SlidesContainer slides={slides} activeSlide={activeSlide} setActiveSlide={setActiveSlide} />
        </div>
    );
};

ViewModeContainer.propTypes = {
    pictures: PropTypes.array,
};

export default ViewModeContainer;
